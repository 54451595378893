<template>
  <v-container>
    <base-card tile flat color="transparent" class="ma-4">
      <v-row>
        <v-col align="center">
          <base-subheading>Welcome</base-subheading>

          <p class="text4 text-wrap" style="font-size: 25px">
            We are the Troops of St. George-Troop 121 from the Church of St.
            Agnes in St. Paul, MN.
          </p>
        </v-col>
        <v-col align="center">
          <v-img max-width="500" src="../../assets/stagnes.png" />
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: "HomeIntroSnip",
};
</script>
