
<template>
  <v-container id="aboutUs" tag="section">
    <base-card tile flat color="white">
      <v-row style="color: black">
        <v-col align="center">
          <base-subheading>About the Troops</base-subheading>
        </v-col>
        <div class="text4 text-wrap ma-8" style="color: black; font-size: 25px">
          <p>
            Mission Statement “The Troops of Saint George apostolate aims to use
            the outdoors as our canvas and the sacraments as our path to light
            the way for the formation of Holy Catholic men and boys. Whether
            called to the vocation of the priesthood, the religious life, or
            that of Holy fatherhood, our fathers and sons will take a prayerful
            pilgrimage together to fulfill Christ’s desire for them to grow in
            virtue and in their Holy Catholic faith as they journey toward
            heaven.”
          </p>
        </div>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>


export default {
  name: "HomeAboutUs",

};
</script>

